<template>
    <div class="log__report" ref="LogReport">
        <template v-if="!isLoadingReport">
            <div class="statistics" v-if="tableHeader.length > 0 && tableData.length> 0">
                <van-row type="flex" class="table-header">
                    <!-- 填报人 -->
                    <van-col class="cell-item" :style="{ width: computedCol + '%'}" :span="colSpan">{{$t('mx_mobile.Log.1585795502656')}}</van-col>
                    <!-- 填报时间 -->
                    <van-col class="cell-item" :style="{ width: computedCol + '%'}" :span="colSpan">{{$t('mx_mobile.Log.1585795504688')}}</van-col>
                    <!-- 填报状态 -->
                    <van-col class="cell-item" :style="{ width: computedCol + '%'}" :span="colSpan">{{$t('mx_mobile.Log.1585795504856')}}</van-col>
                    <template v-for="(item, index) in tableHeader">
                        <van-col class="cell-item" :key="index" :span="colSpan" :style="cusWidth(item)">{{item[0]}}</van-col>
                    </template>
                </van-row>
                <template v-for="(item, index) in tableData">
                    <van-row :key="index" type="flex" class="table-body">
                        <van-col class="cell-item" :style="{ width: computedCol + '%'}" :span="colSpan"><span class="cell-item__cont">{{item.creator}}</span></van-col>
                        <van-col class="cell-item" :style="{ width: computedCol + '%'}" :span="colSpan"><span class="cell-item__cont">{{item.createDate}}</span></van-col>
                        <!-- // status 1: 正常, 2: 保存(不返回) 3: 系统提交, 4：未提交 -->
                        <van-col class="cell-item" :style="{ width: computedCol + '%'}" :class="item.status === 1 ? 't-success' : item.status === 3 ? 't-yellow' : 't-red'" :span="colSpan">
                            <!-- '按时提交' '系统提交' '未提交' -->
                            <span class="cell-item__cont">{{item.status === 1 ? $t('mx_mobile.Log.1585795505015') : item.status === 3 ? $t('mx_mobile.Log.1585795505159') : $t('mx_mobile.Log.1585795505296')}}</span>
                        </van-col>
                        <template v-for="(v, index) in tableHeader">
                            <van-col class="cell-item" :key="index" :span="colSpan" :style="cusWidth(v)" :keys="JSON.stringify(v)">
                                <!-- {{item[v[1]]}} -->
                                <span :class="isIndex(v)">{{item[v[1]]}}</span>
                            </van-col>
                        </template>
                    </van-row>
                </template>
                <!-- 总计 -->
                <van-row type="flex" class="table-body">
                    <van-col class="cell-item" :style="{ width: computedCol + '%'}" :span="colSpan">{{$t('mx_mobile.Log.1585795629882') + this.tableData.length}}</van-col>
                    <template v-for="(v, index) in tableHeader.length + 2">
                        <van-col class="cell-item" :style="{ width: computedCol + '%'}" :key="index" :span="colSpan"></van-col>
                    </template>
                </van-row>
            </div>
            <nodata v-if="lookNameList.length === 0 || (isLoadUp && tableHeader.length === 0 && tableData.length === 0)"></nodata>
        </template>
        <transition name="van-fade">
            <loading v-if="isLoadingReport" size="30px"></loading>
        </transition>
        <filter-report v-if="showFilterReport" :ctrlFilterReport.sync="showFilterReport" @getInitData="getInitData" :userList="userList" :lookNameList="lookNameList" :reportFindData="reportFindData"></filter-report>
    </div>
</template>

<script>
import { formatValidTime, isObject, isString, getWeekForDay, getMonDayAndSunDay } from '@/libs/utils.js'
export default {
    name: 'StatisticsLog',
    props: {
    },
    components: {
        'filter-report': resolve => require(['../Vue/FilterReport/index'], resolve)
    },
    data() {
        return {
            isLoadUp: false, // 数据是否加载完毕
            isLoadingReport: false,
            lookNameList: [],
            userList: [],
            reportFindData: {
                ctId: '',
                scope: '',
                paperId: '',
                paperType: ''
            },
            tableHeader: [],
            tableData: [],
            oldPaperType: '',
            showFilterReport: false,
            remindScope: '' // 提醒的时间
        }
    },
    watch: {
        '$route'(to, from) {
            if (to.name === 'StatisticsLog') {
                this.setMenu()
                this.setHeaderTitle(this.$t('mx_mobile.App.1584682801330')) // '统计'
                this.showRemindContent(to.params)
            } else {
                this.remindScope = ''
                if (this.showFilterReport) this.showFilterReport = false
            }
        }
    },
    created() {
        this.setHeaderTitle(this.$t('mx_mobile.App.1584682801330')) // '统计'
        if (this.$route.name === 'StatisticsLog') {
            this.showRemindContent(this.$route.params)
        }
    },
    computed: {
        colSpan() {
            return 24 / (this.tableHeader.length + 3)
        },
        computedCol() {
            let n = parseInt(this.colSpan / 24 * 10000) / 100
            return n
        }
    },
    mounted() {
        this.setMenu()
    },
    methods: {
        async showRemindContent(params) {
            try {
                // billId: 1, billJson: "2019-10-18"
                if (isObject(params) && params.hasOwnProperty('billId') && params.hasOwnProperty('billJson') && params.billId && params.billJson) {
                    this.reportFindData.paperId = params.billId
                    if (isString(params.billJson) && params.billJson.split(',').length === 2) {
                        // 周报 2019-10-28,2019-11-03 -> 2019-W44
                        this.remindScope = params.billJson
                        this.reportFindData.scope = getWeekForDay(params.billJson.split(',')[0])
                    } else {
                        this.remindScope = params.billJson // 传入提醒的日期
                        this.reportFindData.scope = params.billJson
                    }
                }
                this.isLoadingReport = true
                await this.getPaperName() // 不缓存数据，每次进入请求新数据
                if (this.remindScope && params.billId) {
                    this.reportFindData.paperType = this.lookNameList.filter(v => params.billId === v.paperId)[0].paperType
                }
            } catch (err) {
                console.log(err)
            }
        },
        isIndex(v) {
            // 是否要添加类名垂直居中， 除了文本类型的字段都垂直居中
            try {
                if (v[2] !== 1) {
                    // 字段类型不是文本
                    return 'cell-item__cont'
                } else {
                    return 'cell-item__cusCont'
                }
            } catch (err) {
                console.log(err)
            }
        },
        cusWidth(item) {
            // [name, id, fieldType]
            // 1：文本 2：数字 3：下拉 4：日期 5：外部联系人 100：备注 101：图片 102：附件
            // 指标，数字，下拉,日期列固定宽度
            try {
                return { width: this.computedCol + '%' }
                // if (isNaN(item[1]) && item[1].indexOf('-index') !== -1) {
                //     return { width: '80px' }
                // } else if (item[2] === 2) {
                //     return { width: '80px' }
                // } else if (item[2] === 4 || item[2] === 3) {
                //     return { width: '100px' }
                // } else {
                //     return { width: this.computedCol + '%' }
                // }
            } catch (err) {
                console.log(err)
            }
        },
        setMenu() {
            let menu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Log.1585194785073')) // '筛选'
            menu.onClick(() => {
                this.showFilterReport = true
            })
        },
        async getSendPersons() {
            try {
                this.userList = []
                let param = {
                    type: 'temp', // all, temp(指定paperId)
                    paperId: this.reportFindData.paperId
                }
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_send_user
                let res = await this.axios.get(url, { params: param })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.length > 0) {
                        this.userList = [...res.data]
                    } else {
                        this.userList = []
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        // 获取日志名称 1: look(发给我的), 2: write(我发出的/写日志), 3: subordinate(我下属的)
        async getPaperName() {
            try {
                let param = {
                    type: 'look'
                }
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_name
                let res = await this.axios.get(url, { params: param })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.length > 0) {
                        this.lookNameList = [...res.data]
                        this.reportFindData.paperId = this.remindScope ? this.reportFindData.paperId : this.lookNameList[0].paperId
                        this.reportFindData.paperType = this.remindScope ? this.reportFindData.paperType : this.lookNameList[0].paperType
                        if (this.reportFindData.paperType == 1) {
                            this.reportFindData.scope = this.remindScope || formatValidTime(new Date().getTime(), 'yyyy-MM-dd')
                        } else if (this.reportFindData.paperType == 2) {
                            this.reportFindData.scope = this.remindScope || getMonDayAndSunDay(formatValidTime(new Date().getTime(), 'yyyy-MM-dd')).toString()
                        } else {
                            this.reportFindData.scope = this.remindScope || formatValidTime(new Date().getTime(), 'yyyy-MM')
                        }

                        console.log(this.reportFindData.paperType)

                        console.log(this.reportFindData.scope)
                        // 相同类型的日志模板日期沿用上次选定的
                        this.initData()
                    } else {
                        this.isLoadingReport = false
                        this.lookNameList = []
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        getInitData(arg) {
            let _arg = JSON.parse(JSON.stringify(arg))
            if (_arg.paperId !== this.reportFindData.paperId) {
                this.reportFindData = _arg
                this.initData()
            } else {
                this.reportFindData = _arg
                this.getPaperReport() // 获取日志内容
            }
        },
        initData() {
            this.getPaperField() // 获取日志字段，指标 - 表头
            this.getPaperReport() // 获取日志内容
            this.getSendPersons() // 重新读取日志模板发送人
        },
        async getPaperField() {
            try {
                this.tableHeader = []
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_field
                let res = await this.axios.get(url, { params: { paperId: this.reportFindData.paperId } })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    let fields = []
                    let target = []
                    if (res.data.dailyPaperField.length > 0) {
                        fields = [...res.data.dailyPaperField].map(v => {
                            return [v.cnName, v.fieldId, v.fieldType]
                        })
                    }
                    if (res.data.dailyPaperIndex.length > 0) {
                        target = [...res.data.dailyPaperIndex].map(v => {
                            return [v.name, v.indexId + '-index', '']
                        })
                    }
                    this.tableHeader = [...target, ...fields]
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        async getPaperReport() {
            try {
                this.tableData = []
                this.isLoadUp = false
                this.isLoadingReport = true
                let param = JSON.parse(JSON.stringify(this.reportFindData))
                delete param.paperType
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_report
                let res = await this.axios.get(url, { params: param })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    // status 1: 正常, 2: 保存(不返回) 3: 系统提交, 4：未提交
                    if (res.data.report.length > 0) {
                        this.oldPaperType = this.reportFindData.paperType
                        let arrData = []
                        arrData = res.data.report.map(v => {
                            // 防止指标id和字段id相同, 指标id增加'-index'尾号
                            let indexObj = {}
                            if (v.hasOwnProperty('index') && Object.keys(v.index).length > 0) {
                                for (const key in v.index) {
                                    if (v.index.hasOwnProperty(key)) {
                                        indexObj[key + '-index'] = v.index[key]
                                    }
                                }
                            }
                            let item = {}
                            item = {
                                creator: v.creator,
                                createDate: v.createDate,
                                status: v.status,
                                ...indexObj,
                                ...v.content
                            }
                            return item
                        })
                        this.tableData = [...arrData]
                    }
                    if (this.showFilterReport) this.showFilterReport = false
                } else {
                    this.$toast.fail(res.msg)
                }
                this.isLoadingReport = false
                this.isLoadUp = true
                this.$nextTick(() => {
                    this.$refs.LogReport && this.$refs.LogReport.scrollTo(0, 0)
                })
            } catch (err) {
                console.log(err)
            }
        }
    },
    beforeDestroy() { }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
